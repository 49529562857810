import md5 from "blueimp-md5";

const url = "https://mobileapi.ipurls.pl/lead";
const user = "premium_www_iprospect";
const pass = "Q5dox9tVCf83djzkg7S1NKotlv2g6JLA!@";

const call = url => (method, parameters) => {
  const headers = {};

  const serializedData = JSON.stringify(parameters);
  const form = new FormData();
  form.append("method", method);
  form.append("input_type", "JSON");
  form.append("response_type", "JSON");
  form.append("rest_data", serializedData);

  return fetch(url, {
    method: "POST",
    body: form,
    headers
  }).then(res => res.json());
};

export const sendLeadToIp = (hashNumber, leadId) => {
  const form = new FormData();
  form.append("hashedPhone", hashNumber);
  form.append("leadId", leadId);
  return fetch('https://mobileapi.ipurls.pl/', {
    method: "POST",
    body: form,
  })
}

const readyCall = call(url);

const login = credentials => {
  const {
    user,
    pass
  } = credentials;
  const login_parameters = {
    user_auth: {
      user_name: user,
      password: md5(pass),
      version: 1
    },
    application_name: "lp1_premium_mobile_by_iprospect"
  };
  return readyCall("login", login_parameters);
};

const setEntry = values => sessionId => {
  const entry_parameters = {
    session: sessionId,
    module_name: "kontk_klientIndywidualny",
    "name_value_list": [...values]
  }
  return readyCall("set_entry", entry_parameters)
}

const logout = sessionid => {
  return readyCall("logout", {
    session: sessionid
  });
}

const sendValues = (values) => {
  const setEntryWithValues = setEntry(values);
  return login({
    user,
    pass
  }).then(data => setEntryWithValues(data.id).then((response) => {
    logout(data.id);
    return response.id;
  }));
}

export default sendValues;