import IMask from 'imask';
import validate from 'validate.js';
// import $ from 'jquery';

const phoneInput = document.getElementById("phoneInput");
const phoneInputPopup = document.getElementById("phoneInputPopup");

const maskOptions = {
    mask: '000-000-000'
}
new IMask(phoneInput, maskOptions);
new IMask(phoneInputPopup, maskOptions);

const constraints = {
    userphone:{
        presence: {
            message: "^Pole wymagane"
        },
        format: {
            pattern: /^\d{3}-\d{3}-\d{3}$/,
            message: "^Nieprawidłowy format"
        }
    },
    legal1:{
        presence: {
            message: "^Pole wymagane"
        },
        inclusion: {
            within: [true],
            message: "^Pole wymagane"
          }
    },
    
}

const handleErrors = (errorObj, formId) => {
    const inputWraps = $(`#${formId} .c-form__input-wrap`);
    for(let wrapper of inputWraps){
        wrapper.classList.remove('isError');
    }
    for(let elemName in errorObj){
        const elemWrap = $(`#${formId} [name=${elemName}]`).parents('.c-form__input-wrap');
        elemWrap.addClass('isError');
        elemWrap.find('.errors').text = errorObj[elemName][0];
    }  
}



const validateForm = (id) => {
    const formValues = validate.collectFormValues(document.getElementById(id));
    const validateResult = validate(formValues, constraints);
    const isValid = (typeof validateResult !== 'undefined') ? false : true;
    handleErrors(validateResult, id);
    return {isValid, values: {...formValues}};
}

export default validateForm; 