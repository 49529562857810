export const getParent = (elem, className) => {
    let node = elem;
    while(!node.classList.contains(className)){
        node = node.parentElement;
        if(node === null) break;
    }
    return node;
}

export const getTodayDate = () => {
    let today = new Date(),
    dd = today.getDate(),
    mm = today.getMonth()+1,
    yyyy = today.getFullYear();

    dd = (dd < 10) ? '0'+dd : dd;
    mm = (mm < 10) ? '0'+mm : mm;

    return `${yyyy}-${mm}-${dd}`;
}