import {
    getSlider
} from 'simple-slider';
import {getParent, getTodayDate} from './helpers';
import md5 from "blueimp-md5";
import Hammer from 'hammerjs';
import validateForm from './validate';
import sendValues, {
    sendLeadToIp
} from './api';


// SLIDER //

const sliderElem = document.getElementById("carousel");
const sliderItems = sliderElem.getElementsByClassName("c-carousel__item");
const leftBtn = document.getElementById("slide-left");
const rightBtn = document.getElementById("slide-right");
let isNext = true;

const config = {
    container: sliderElem,
    prop: "opacity",
    unit: '',
    init: 0,
    show: 1,
    end: 0,
    delay: 10,
    onChange: onSlideChange
}
const slider = getSlider(config);
onSlideChange(0, 0);

leftBtn.onclick = function (e) {
    slider.prev();
    e.preventDefault();
}

rightBtn.onclick = function (e) {
    slider.next();
    e.preventDefault();
}

const manager = new Hammer.Manager(sliderElem);

const Swipe = new Hammer.Swipe({
    direction: Hammer.DIRECTION_HORIZONTAL
});

manager.add(Swipe);

manager.on('swipeleft', function () {
    if (isNext) {
        slider.reverse();
        isNext = false;
    }
    slider.next();
});

manager.on('swiperight', function () {
    if (!isNext) {
        slider.reverse();
        isNext = true;
    }
    slider.next();
});

function onSlideChange(curent, next) {
    if (isNext) {
        sliderElem.style.height = sliderItems[next].offsetHeight + "px";
    } else {
        sliderElem.style.height = sliderItems[curent].offsetHeight + "px";
    }
}

//POPUP FORM
$('.js-popup-trigger').on('click', function(){
    $('.form__popup').toggle('fast');
});


//FORM

// Scroll form on input focus
document.querySelector('#phoneInput').addEventListener('focus', function(){
   if(window.innerWidth <= 576){
    const parentFormContainer = getParent(this,'lead-form');
    window.scrollTo({
        top: parentFormContainer.offsetTop,
        behavior: 'smooth'
    });
   } 
});

const toggleSendStatus = statusType => {
    $('.js-form').fadeTo(400, 0);
    $('.js-form__status').fadeOut();
    $(`.js-form__status_${statusType}`).fadeIn();
    if (statusType !== 'loading') {
        setTimeout(() => {
            $('.js-form__status').fadeOut();
            $('.js-form').fadeTo(400, 1);
        }, 1500)
    }
}

const sendForm = (e) => {
    e.preventDefault(); 
    const parentId = e.target.parentElement.id;
    
    const validatedForm = validateForm(parentId);
    if (validatedForm.isValid) {
        toggleSendStatus('loading');
        let values = [];

        const url = new URL(window.location.href);
        const phoneNo = validatedForm.values['userphone'].replace(/-/g, "");
        values.push({
            name: 'operator_lead_c',
            value: "Iprospect"
        });
        values.push({
            name: 'telefon_komorkowy',
            value: phoneNo
        });
        values.push({
            name: 'zgoda_rodo_v2_1_c',
            value: validatedForm.values['legal1']
        });
        values.push({
            name: 'data_otrzymania_c',
            value: getTodayDate()
        });

        if (url.searchParams.get("utm_source")) {
            values.push({
                name: 'utm_source_c',
                value: url.searchParams.get("utm_source")
            });
        }
        if (url.searchParams.get("utm_medium")) {
            values.push({
                name: 'utm_medium_c',
                value: url.searchParams.get("utm_medium")
            });
        }

        sendValues(values).then((leadId) => {
            document.getElementById("leadForm").reset();
            toggleSendStatus('success');
            sendLeadToIp(md5(phoneNo), leadId);
            dataLayer.push({
                event: 'SendForm',
                formId: parentId,
                leadId
            })
        });
    }
}

const inputs = document.getElementsByTagName('input');
for (let input of inputs) {
    input.oninput = function(e){
        validateForm(e.target.form.id);
    };
}

$('.c-form__input_submit').on('click', sendForm);
